import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const CtaBarStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-cta-bar-original,
      .leshen-sticky-bar .sticky {
        padding: 8px 32px;
        background-color: #009fdb !important;

        > div {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
        }

        button {
          color: #fff;
          border-radius: 32px !important;
          padding: 8px 16px;
          font-size: 18px;
          min-width: max-content;
          background-color: #0057b8;

          &.left-button {
            border: 1px solid #fff !important;

            span {
              color: #fff;
            }
          }

          &.right-button {
            background-color: #8cd3f5 !important;

            span,
            svg {
              color: #000;
              fill: #000;
            }
          }

          &:hover {
            text-decoration: none;
            background-color: none;
          }

          svg {
            fill: #fff;
            width: 24px;
            height: 24px;
            margin-right: 8px !important;
          }

          .button-text {
            font-size: 18px;
            line-height: 24px;
            margin-left: 0 !important;
          }
        }

        @media (min-width: ${theme.breakpoints.lg}) {
          .left-button {
            border-radius: 32px;
            border: 1px solid #fff !important;
          }
        }
      }
    `}
  />
))

export default CtaBarStyles
