module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.attexperts.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-PCKT6L","brandTokens":["ATT","DTV","DTVB","ATTB"],"mapiBrandToken":"ATT","siteName":"attexperts","alternateName":"attexperts.com","siteURL":"https://www.attexperts.com","defaultTitleTemplate":"","defaultPhone":"8885619189","phoneSymbol":"-","defaultPromoCode":"26094","removeTrailingSlashes":true,"smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046059"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"attexperts","short_name":"attexperts","start_url":"/","background_color":"#0057B8","theme_color":"#0057B8","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"57f9a8274a0f93d0e98165488ebbd705"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
