import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FooterStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-footer {
        .top-section {
          padding-bottom: 30px;
          padding-top: 30px;
          border-top: 1px solid #e5e5e5;
        }

        .bottom-section {
          padding-top: 24px;
          padding-bottom: 24px;
          background-color: #fff !important;

          .nav {
            padding: 0 10px;
          }
        }

        .legal-section {
          background-color: #f2f2f2;
        }
      }
    `}
  />
))

export default FooterStyles
